<nav class="bg-dark-blue">
  <div class="container container-wide flex justify-start items-center">
    <img [src]="logoImage.path" [style.height.px]="logoImage.height" routerLink="/" alt="Logo" />

    <div class="title flex-1">
      {{ title }}
    </div>

    <app-header-menu> </app-header-menu>
  </div>
</nav>
