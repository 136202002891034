export interface Table {
  name: string;
  columns: TableColumn[];
}

export interface TableColumn {
  name: string;
  isForDashboard: boolean;
}

export const tables: Table[] = [
  {
    name: 'Extract 1',
    columns: [
      {
        name: 'agl_party_id',
        isForDashboard: false,
      },
      {
        name: 'customer_id',
        isForDashboard: false,
      },
      {
        name: 'tier',
        isForDashboard: true,
      },
      {
        name: 'best_country_cd',
        isForDashboard: false,
      },
      {
        name: 'sub_scheme',
        isForDashboard: false,
      },
      {
        name: 'tenure',
        isForDashboard: false,
      },
      {
        name: 'household',
        isForDashboard: false,
      },
      {
        name: 'ok_email_ind',
        isForDashboard: false,
      },
      {
        name: 'active_12',
        isForDashboard: true,
      },
      {
        name: 'balance',
        isForDashboard: false,
      },
      {
        name: 'balance_band',
        isForDashboard: false,
      },
      {
        name: 'aii',
        isForDashboard: true,
      },
      {
        name: 'postcode',
        isForDashboard: true,
      },
      {
        name: 'multi_flag',
        isForDashboard: false,
      },
      {
        name: 'cc_active_flag',
        isForDashboard: true,
      },
      {
        name: 'cust_lyty_seg_cdb',
        isForDashboard: false,
      },
      {
        name: 'bus_les',
        isForDashboard: false,
      },
    ],
  },
  {
    name: 'Extract 2a',
    columns: [
      {
        name: 'aii',
        isForDashboard: true,
      },
      {
        name: 'postcode',
        isForDashboard: true,
      },
      {
        name: 'agl_party_id',
        isForDashboard: false,
      },
      {
        name: 'reporting_month',
        isForDashboard: true,
      },
      {
        name: 'nap_category',
        isForDashboard: true,
      },
      {
        name: 'partner_nm',
        isForDashboard: true,
      },
      {
        name: 'avios_1',
        isForDashboard: true,
      },
    ],
  },
  {
    name: 'Extract 2a LL',
    columns: [
      {
        name: 'aii',
        isForDashboard: true,
      },
      {
        name: 'postcode',
        isForDashboard: true,
      },
      {
        name: 'agl_party_id',
        isForDashboard: false,
      },
      {
        name: 'first_col_dt',
        isForDashboard: false,
      },
      {
        name: 'reporting_month',
        isForDashboard: true,
      },
      {
        name: 'nap_category',
        isForDashboard: true,
      },
      {
        name: 'partner_nm',
        isForDashboard: true,
      },
      {
        name: 'merchant',
        isForDashboard: true,
      },
      {
        name: 'avios_1',
        isForDashboard: true,
      },
    ],
  },
];
