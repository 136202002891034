import isFunction from '../../core/utils/is-function.mjs';
import locale from '../../locale/en.mjs';
import merge from '../../core/utils/merge.mjs';
import isObject from '../../core/utils/is-object.mjs';
import isString from '../../core/utils/is-string.mjs';
import truncateNumber from '../../core/utils/truncate-number.mjs';
import BUILT_IN_FORMATTERS from '../constants/built-in-formatters.mjs';
import roundHalfAwayFromZero from '../../core/utils/round-half-away-from-zero.mjs';
const areDelimitersValid = delimiters => {
  return !!(delimiters === null || delimiters === void 0 ? void 0 : delimiters.decimal) && isString(delimiters === null || delimiters === void 0 ? void 0 : delimiters.thousands) && delimiters.decimal !== delimiters.thousands;
};
const resolveOptionsLocale = optionsLocale => {
  const defaultLocale = locale;
  if (!isObject(optionsLocale)) return defaultLocale;
  return merge(optionsLocale, {
    delimiters: areDelimitersValid(optionsLocale.delimiters) ? optionsLocale.delimiters : defaultLocale.delimiters,
    abbreviations: optionsLocale.abbreviations || defaultLocale.abbreviations,
    ordinal: optionsLocale.ordinal || defaultLocale.ordinal
  });
};
const resolveRoundingOption = roundingOption => {
  switch (roundingOption) {
    case 'ceil':
      return Math.ceil;
    case 'floor':
      return Math.floor;
    case 'truncate':
      return truncateNumber;
    case 'half-up':
      return Math.round;
    case 'half-away-from-zero':
      return roundHalfAwayFromZero;
    default:
      return isFunction(roundingOption) ? roundingOption : roundHalfAwayFromZero;
  }
};
const resolveOptionsFormatters = optionsFormatters => {
  if (!optionsFormatters) return BUILT_IN_FORMATTERS;
  return isFunction(optionsFormatters) ? optionsFormatters(BUILT_IN_FORMATTERS) : [...optionsFormatters, ...BUILT_IN_FORMATTERS];
};
const resolveFormatOptions = formatOptions => {
  var _a, _b, _c;
  const options = formatOptions || {};
  const resolvedRoundingFunction = resolveRoundingOption(options.rounding);
  const resolvedLocale = resolveOptionsLocale(options.locale);
  const resolvedFormatters = resolveOptionsFormatters(options.formatters);
  return {
    defaultPattern: options.defaultPattern || '0,0.##########',
    nullFormat: options.nullFormat || '',
    nanFormat: options.nanFormat,
    zeroFormat: options.zeroFormat,
    locale: resolvedLocale,
    rounding: resolvedRoundingFunction,
    type: options.type,
    scalePercentage: (_a = options.scalePercentage) !== null && _a !== void 0 ? _a : true,
    trim: (_b = options.trim) !== null && _b !== void 0 ? _b : true,
    formatters: resolvedFormatters,
    currency: options.currency,
    signedZero: !!options.signedZero,
    nonBreakingSpace: (_c = options.nonBreakingSpace) !== null && _c !== void 0 ? _c : false
  };
};
export default resolveFormatOptions;