const addThousandsSeparatorToValueIntegerPart = (valueIntegerPartWithLeadingZerosAndWithoutSign, patternRules, options) => {
  const {
    delimiters,
    digitGroupingStyle
  } = options.locale;
  const {
    grouping
  } = patternRules;
  if (!grouping || !delimiters.thousands) {
    return valueIntegerPartWithLeadingZerosAndWithoutSign;
  }
  const valueAsString = valueIntegerPartWithLeadingZerosAndWithoutSign;
  const thousandsSeparator = delimiters.thousands;
  const digitGrouping = !!(digitGroupingStyle === null || digitGroupingStyle === void 0 ? void 0 : digitGroupingStyle.length) ? digitGroupingStyle : [3];
  const restDigitGrouping = [...digitGrouping];
  let output = '';
  let groupingSubIteration = 1;
  for (let i = valueAsString.length - 1; i >= 0; i--) {
    if (groupingSubIteration === restDigitGrouping[0] && i !== 0) {
      output = thousandsSeparator + valueAsString[i] + output;
      if (restDigitGrouping.length > 1) restDigitGrouping.shift();
      groupingSubIteration = 1;
    } else {
      output = valueAsString[i] + output;
      groupingSubIteration += 1;
    }
  }
  return output;
};
export default addThousandsSeparatorToValueIntegerPart;