import { patternIncludes, patternReplace } from '../utils/pattern-regexp-utils.mjs';
import toObject from '../../core/utils/to-object.mjs';
import { unitScale } from '../../core/utils/unit-scale.mjs';
import formattedStringToNumber from '../parse/utils/formatted-string-to-number.mjs';
import numberToFormattedNumber from '../format/number-to-formatted-number/number-to-formatted-number.mjs';
const decimalSuffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const binarySuffixes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
const allSuffixes = decimalSuffixes.concat(binarySuffixes.slice(1));
/** Avoid collision with BPS format @see formats|bps.ts */
const unformatRegex = `(${allSuffixes.join('|').replace(/B/g, 'B(?!PS)')})`;
const bytesDecimalScale = unitScale({
  base: 'B',
  scale: toObject(decimalSuffixes, (unit, unitIndex) => [unit, Math.pow(1000, unitIndex)])
});
const bytesBinaryScale = unitScale({
  base: 'B',
  scale: toObject(binarySuffixes, (unit, unitIndex) => [unit, Math.pow(1024, unitIndex)])
});
const bytesFormatter = {
  name: 'bytes',
  regexps: {
    format: /([0\s]b[bd])|(b[bd][0\s])/,
    unformat: (string, options) => options.type === 'bytes' ? new RegExp(unformatRegex).test(string) : false
  },
  format: (number, pattern, options) => {
    const scale = patternIncludes(pattern, 'bb') ? bytesBinaryScale : bytesDecimalScale;
    const [scaledValue, scaledValueUnit] = scale.toBest(number, 'B');
    const patternWithEscapedBytes = patternReplace(pattern, /b[bd]/, `'ɵbytesɵ'`);
    const formatResult = numberToFormattedNumber(scaledValue, patternWithEscapedBytes, options);
    return formatResult.replace('ɵbytesɵ', scaledValueUnit || '');
  },
  unformat: (string, options) => {
    var _a;
    const number = formattedStringToNumber(string.replace(new RegExp(unformatRegex), ''), options);
    const suffix = ((_a = string.match(unformatRegex)) === null || _a === void 0 ? void 0 : _a[0]) || '';
    const scale = !!bytesBinaryScale.scaleDefinition.scale[suffix] ? bytesBinaryScale : bytesDecimalScale;
    return number ? scale.toBase(number, suffix) : number;
  }
};
export default bytesFormatter;