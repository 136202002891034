import { Component, Input, OnChanges } from '@angular/core';

import { NumeralService } from '../../../shared/numeral';
import { KpiMetric, Kpis, Metric } from '../../';

@Component({
  selector: 'app-home-kpis-metric',
  templateUrl: './home-kpis-metric.component.html',
  styleUrls: ['./home-kpis-metric.component.scss'],
})
export class HomeKpisMetricComponent implements OnChanges {
  @Input() kpis: Kpis;
  @Input() metric: Metric;
  @Input() isNonCustomerTotalPopulation = false;
  @Input() layout = 'row';
  @Input() isLoading = false;
  @Input() hasUplift = true;

  public customerAmount: string;
  public nonCustomerAmount: string;

  constructor(private numeralService: NumeralService) {}

  ngOnChanges(): void {
    if (!this.kpis) {
      return;
    }

    const formatOfMetric = () => {
      if (this.metric.isCurrency) {
        return this.metric.isAbbreviated ? '$0a' : '$0.00';
      }

      return '0a';
    };

    const formatPattern = formatOfMetric();
    this.customerAmount = this.numeralService.format(this.kpiMetric.customers, formatPattern);
    this.nonCustomerAmount = this.numeralService.format(this.kpiMetric.nonCustomers, formatPattern);
  }

  get kpiMetric(): KpiMetric {
    return this.kpis[this.metric.id];
  }

  get customerTooltip(): string {
    return `${this.kpiMetric.customers}`;
  }

  get nonCustomerTooltip(): string {
    return `${this.kpiMetric.nonCustomers}`;
  }

  get indexTooltip(): string {
    return `${this.kpiMetric.index}`;
  }

  get upliftTooltip(): string {
    return `${this.metric.name} per Avios Customer / ${this.metric.name} per Non-Avios Customer`;
  }

  get widthClass(): string {
    if (this.layout === 'column') {
      return 'w-full';
    }

    return this.hasUplift ? 'w-1/3' : 'w-1/2';
  }
}
