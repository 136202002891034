import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Metric, PartnerCategoryPerformance, HomeService, PerformanceApiParams } from '../../';
import { Filters, FiltersService } from '../../../shared/filters';
import { Dataset, DateRange } from '../../../shared/common-api';

@Component({
  selector: 'app-home-performance-merchant-category-charts',
  templateUrl: './home-performance-merchant-category-charts.component.html',
  styleUrls: ['./home-performance-merchant-category-charts.component.scss'],
})
export class HomePerformanceMerchantCategoryChartsComponent implements OnChanges {
  @Input() dataset: Dataset;
  @Input() filters: Filters;
  @Input() metric: Metric;
  @Input() amountKey: string;
  @Input() dateRange: DateRange;
  @Input() isNonCustomerTotalPopulation: boolean;
  @Input() hasCustomerCategoryData: boolean;

  public partnerCategoryPerformance: PartnerCategoryPerformance;
  public merchantChartErrors: string[];
  public categoryChartErrors: string[];
  public isLoading = true;

  private currentQuery: Subscription;

  constructor(private filtersService: FiltersService, private homeService: HomeService) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.merchantChartErrors = this.chartErrors(false);
    this.categoryChartErrors = this.chartErrors(true);

    if (simpleChanges['filters'] || simpleChanges['dateRange']) {
      this.retrieveData();
    }
  }

  retrieveData(): void {
    this.isLoading = true;

    if (this.currentQuery !== undefined) {
      this.currentQuery.unsubscribe();
    }

    const params: PerformanceApiParams = {
      ...this.filtersService.paramsFrom(this.filters),
      startOn: this.dateRange.startOn.toISODate(),
      endOn: this.dateRange.endOn.toISODate(),
    };

    this.currentQuery = this.homeService
      .partnerCategoryPerformance(params)
      .pipe(first())
      .subscribe((partnerCategoryPerformance: PartnerCategoryPerformance) => {
        this.partnerCategoryPerformance = partnerCategoryPerformance;

        this.isLoading = false;
      });
  }

  chartErrors(isCategory = false): string[] {
    const errors = [];

    const isMetricChartable = this.metric.id !== 'transactors' && this.metric.id !== 'avgSpend';
    if (!isMetricChartable) {
      errors.push('metric');
    }

    const hasChartableTimeframe =
      this.amountKey === 'totals' ||
      this.dateRange.startOn >= DateTime.fromISO(this.dataset.startOn).plus({ years: 1 });

    if (!hasChartableTimeframe) {
      errors.push('date range');
    }

    if (isCategory && this.filters.populationSegment.id !== 'CUSTOMER_WALLET') {
      errors.push('population segment');
    }

    return errors.length === 0 ? null : errors;
  }
}
