import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PingCallbackComponent } from '@comsig/core';

const routes: Routes = [
  {
    path: 'implicit/callback',
    component: PingCallbackComponent,
  },
  {
    path: 'admin',
    redirectTo: 'admin/user-admin',
    pathMatch: 'full',
  },
  {
    path: 'admin/user-admin',
    loadChildren: () =>
      import('./admin/user-admin/user-admin.module').then((m) => m.UserAdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
