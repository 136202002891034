const locale = {
  code: 'en',
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: '|||K|||M|||B|||T',
  ordinal: number => {
    const b = number % 10;
    return Math.floor(number % 100 / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
  }
};
export default locale;