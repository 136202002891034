/**
 * This function doesn't work with non-primitive arguments
 */
const memoize = fn => {
  const cache = {};
  return function (...args) {
    const cacheKey = args.length > 1 ? args.join('-(:-:)-') : args[0];
    if (cacheKey in cache) {
      return cache[cacheKey];
    }
    const result = fn.apply(this, args);
    cache[cacheKey] = result;
    return result;
  };
};
export default memoize;