import isNil from '../../core/utils/is-nil.mjs';
import isFunction from '../../core/utils/is-function.mjs';
import isNaNNumber from '../../core/utils/is-nan-number.mjs';
import isString from '../../core/utils/is-string.mjs';
import { patternRemoveEscapedText } from '../utils/pattern-regexp-utils.mjs';
import numberToFormattedNumber from './number-to-formatted-number/number-to-formatted-number.mjs';
import resolveFormatOptions from '../utils/resolve-format-options.mjs';
const getFormatFunctionIfMatch = (pattern, resolvedOptions) => {
  const patternWithoutEscapedText = patternRemoveEscapedText(pattern);
  for (const formatter of resolvedOptions.formatters) {
    const matcher = formatter.regexps.format;
    if (!matcher) continue;
    const matcherResult = isFunction(matcher) ? matcher(pattern, resolvedOptions) : !!patternWithoutEscapedText.match(matcher);
    if (matcherResult) return formatter.format;
  }
};
const format = (value, pattern, options) => {
  var _a;
  try {
    const resolvedValue = isString(value) ? parseFloat(value) : value;
    const resolvedOptions = resolveFormatOptions(options);
    const resolvedPattern = pattern || resolvedOptions.defaultPattern;
    let output;
    if (resolvedValue === Infinity || resolvedValue === -Infinity) {
      output = resolvedValue > 0 ? '∞' : '-∞';
    } else if (isNaNNumber(resolvedValue)) {
      return isString(resolvedOptions.nanFormat) ? resolvedOptions.nanFormat : isString(resolvedOptions.nullFormat) ? resolvedOptions.nullFormat : '';
    } else if (isNil(resolvedValue)) {
      output = isString(resolvedOptions.nullFormat) ? resolvedOptions.nullFormat : '';
    } else if (resolvedValue === 0 && isString(resolvedOptions.zeroFormat)) {
      output = resolvedOptions.zeroFormat;
    } else {
      // <!> Here value should always be a number
      const resolvedValueAsNumber = resolvedValue || 0;
      const formatFunctionFromFormatters = getFormatFunctionIfMatch(resolvedPattern, resolvedOptions);
      const resolvedFormatFunction = formatFunctionFromFormatters || numberToFormattedNumber;
      output = resolvedFormatFunction(resolvedValueAsNumber, resolvedPattern, resolvedOptions);
    }
    // Ensures that it always returns an string
    output = isString(output) ? output : '';
    // Replaces spaces with non-breaking spaces if needed
    output = resolvedOptions.nonBreakingSpace ? output.replace(/ /g, _ => '\u00A0') : output;
    // Trims the output if needed
    output = resolvedOptions.trim ? output.trim() : output;
    return output;
  } catch (_error) {
    return ((_a = options) === null || _a === void 0 ? void 0 : _a._errorFormat) || '';
  }
};
export default format;