<div class="help-toc-container">
  <ul>
    @for (section of sections; track section) {
      <li>
        <span (click)="selectSection(section)">
          {{ section.title }}
        </span>
        @if (section.children) {
          <ul>
            @for (subSection of section.children; track subSection) {
              <li>
                <span (click)="selectSection(subSection)">
                  {{ subSection.title }}
                </span>
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>
</div>
