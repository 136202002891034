import { Injectable } from '@angular/core';

import { HotjarService } from './hotjar.service';
import { TrackJSService } from './trackjs.service';
import { AppSettings, AuthService } from '@comsig/core';

@Injectable({
  providedIn: 'root',
})
export class SaasProvidersService {
  constructor(
    private trackJSService: TrackJSService,
    private hotjarService: HotjarService,
    private authService: AuthService
  ) {}

  init(appSettings: AppSettings) {
    this.trackJSService.init(appSettings.trackjs);

    if (this.authService.authUser.username.endsWith('verisk.com')) {
      return;
    }

    this.hotjarService.init(appSettings.hotjar);
  }
}
