import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { StatusRoutingModule } from './status-routing.module';

import { StatusComponent } from './status.component';

import { StatusService } from './status.service';

@NgModule({
  imports: [CommonModule, SharedModule, StatusRoutingModule],
  providers: [StatusService],
  declarations: [StatusComponent],
})
export class StatusModule {}
