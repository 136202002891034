import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService, ApiEntity, PaginatedList, AuthService } from '@comsig/core';

import {
  UserActivity,
  UserActivityTrendlinesDate,
  UserActivityReportParams,
  UserActivityUserTotals,
  UserActivityLogins,
} from './';

@Injectable({
  providedIn: 'root',
})
export class UserActivitiesService extends ApiEntity<UserActivity> {
  protected override endpoint = 'user-activities';

  constructor(protected override api: ApiService, private router: Router, private authService: AuthService) {
    super(api);
  }

  storeActivity(userActivity: Partial<UserActivity>): void {
    const product =
      userActivity.product ?? this.router.url.includes('/merchant-tracker')
        ? 'MERCHANT_TRACKER'
        : 'CATEGORY_TRACKER';

    const notes =
      userActivity.notes ?? this.authService.authUserHasRole('ROLE_FREEMIUM') ? 'FREEMIUM' : null;

    super
      .save({
        ...userActivity,
        product,
        notes,
      })
      .pipe(first())
      .subscribe(() => {});

    return;
  }

  override getList(params = {}): Observable<PaginatedList<UserActivity>> {
    return this.api.get('user-activities', params);
  }

  totals(): Observable<UserActivityUserTotals> {
    return this.api.get(`${this.endpoint}/totals`);
  }

  trendline(params: UserActivityReportParams): Observable<UserActivityTrendlinesDate[]> {
    return this.api.get(`${this.endpoint}/trendline`, params);
  }

  logins(params: UserActivityReportParams): Observable<UserActivityLogins> {
    return this.api.get(`${this.endpoint}/logins`, params);
  }

  userActions(params: UserActivityReportParams): Observable<any> {
    return this.api.get(`${this.endpoint}/actions`, params);
  }
}
