import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ComsigCoreModule } from '@comsig/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { StatusModule } from './status/status.module';
import { ReleaseNotesModule } from './release-notes/release-notes.module';
import { HelpModule } from './help/help.module';
import { HomeModule } from './home/home.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComsigCoreModule,
    SharedModule,
    LayoutModule,
    StatusModule,
    HomeModule,
    ReleaseNotesModule,
    HelpModule,
    NgxPermissionsModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
