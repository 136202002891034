<div class="user-menu">
  <span class="menu-item" routerLink="/admin" *ngxPermissionsOnly="'viewAdmin'">Admin</span>
  <span class="menu-item" routerLink="/help">Help <i class="cs cs-help-outline"></i></span>

  <!--  <span class="menu-item" (click)="openReleaseNotes()">
    <span
      [matBadge]="$unreadUpdatesCount | async"
      [matBadgeHidden]="($unreadUpdatesCount | async) === 0"
      matBadgeOverlap="false"
      matBadgeColor="warn"
    >
      Updates
    </span>
  </span>-->
  <span class="name">
    {{ name }}
  </span>
  <span>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
    <button id="e2e-user-icon" mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon class="cs cs-light-user-circle" aria-hidden="true"> </mat-icon>
    </button>
  </span>
</div>
