import { Injectable } from '@angular/core';
import { HotjarSettings } from '@comsig/core';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  init(settings: HotjarSettings) {
    if (settings.enabled === false) {
      return;
    }

    (function(h: any, o: any, t: any, j: any, a?: any, r?: any) {
      h.hj =
        h.hj ||
        function() {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: settings.hjid, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}
