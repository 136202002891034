const toObject = (arr, entriesResolver) => {
  const object = {};
  for (let i = 0; i < arr.length; ++i) {
    if (i in arr) {
      const [key, value] = entriesResolver(arr[i], i);
      object[key] = value;
    }
  }
  return object;
};
export default toObject;