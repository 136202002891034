import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';

import { HomeIndexComponent } from './home-index/home-index.component';
import { HomeKpisComponent } from './home-kpis/home-kpis.component';
import { HomeKpisMetricComponent } from './home-kpis/home-kpis-metric/home-kpis-metric.component';
import { HomeKpisCustomersChartComponent } from './home-kpis/home-kpis-customers-chart/home-kpis-customers-chart.component';
import { HomePerformanceComponent } from './home-performance/home-performance.component';
import { HomePerformanceMerchantCategoryChartsComponent } from './home-performance/home-performance-merchant-category-charts/home-performance-merchant-category-charts.component';
import { HomePerformanceMerchantCategoryChartComponent } from './home-performance/home-performance-merchant-category-chart/home-performance-merchant-category-chart.component';
import { HomePerformanceTrendlineComponent } from './home-performance/home-performance-trendline/home-performance-trendline.component';

@NgModule({
  imports: [LayoutModule, SharedModule, HomeRoutingModule],
  declarations: [
    HomeIndexComponent,
    HomeKpisComponent,
    HomeKpisMetricComponent,
    HomeKpisCustomersChartComponent,
    HomePerformanceComponent,
    HomePerformanceMerchantCategoryChartsComponent,
    HomePerformanceMerchantCategoryChartComponent,
    HomePerformanceTrendlineComponent,
  ],
})
export class HomeModule {}
