<mat-card>
  <mat-card-content>
    <div class="flex gap-x-4">
      <div class="flex-1">
        <h1>Performance Breakdown - {{ metric.name }}</h1>
        <h3>{{ subtitle }}</h3>
      </div>
      <div class="flex gap-x-4 justify-start items-center">
        <div class="date-range">
          <app-dataset-date-range-picker
            [dataset]="dataset"
            (dateRangeChange)="onDateRangeChange($event)"
            >
          </app-dataset-date-range-picker>
        </div>

        <mat-form-field class="transparent-form-field">
          <mat-label>Select Metric</mat-label>
          <mat-select [(ngModel)]="metric">
            @for (metric of metrics; track metric) {
              <mat-option [value]="metric">
                {{ metric.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <button
          mat-icon-button
          matTooltip="Download data to Excel"
          matTooltipPosition="above"
          (click)="download()"
          >
          <mat-icon matSuffix class="cs cs-file-download"></mat-icon>
        </button>
      </div>
    </div>

    <app-card-tab-nav
      [tabs]="tabs"
      (selectedIndexChange)="tabChanged($event)"
      [removePadding]="true"
    ></app-card-tab-nav>

    @if (isReady) {
      <div class="chart-block">
        <app-home-performance-merchant-category-charts
          [dataset]="dataset"
          [filters]="filters"
          [metric]="metric"
          [amountKey]="amountKey"
          [dateRange]="dateRange"
          [hasCustomerCategoryData]="hasCustomerCategoryData"
          [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
        ></app-home-performance-merchant-category-charts>
      </div>
      <div class="chart-block">
        <app-home-performance-trendline
          [filters]="filters"
          [metric]="metric"
          [amountKey]="amountKey"
          [dateRange]="dateRange"
          [hasCustomerCategoryData]="hasCustomerCategoryData"
          [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
        ></app-home-performance-trendline>
      </div>
    }
  </mat-card-content>
</mat-card>
