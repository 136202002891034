<div class="world"></div>

@if (showStatus) {
  <div class="status-container">
    <div class="status-img-container">
      @if (isApiUp()) {
        <img src="/assets/images/status/api-status-gears.gif" />
      }
      @if (!health || !isApiUp()) {
        <img src="/src/assets/images/status/api-status-down.png" />
      }
    </div>
    <div class="status-info">
      <h1 id="e2e-api-status">The API is {{ health?.status || 'down' | lowercase }}.</h1>
      @if (uiVersion) {
        <div>UI Version: {{ uiVersion.version }} ({{ uiVersion.hash }})</div>
      }
      @if (health) {
        <div>
          <div title="Platform built on {{ apiVersion.git.commit.time | date }}">
            API Version: {{ apiVersion.build.version }} ({{ apiVersion.git.commit.id }})
          </div>
        </div>
      }
    </div>
  </div>
}
