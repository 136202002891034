@if (isLoading) {
  <mat-spinner class="spinner"> </mat-spinner>
}
<div class="add-header" *ngxPermissionsOnly="['updateReleaseNotes']">
  <app-add-button
    label="New Release Notes"
    (addClicked)="startPublishing()"
    >
  </app-add-button>
</div>
@if (isPublishing) {
  <app-release-notes-editor
    (published)="onPublish()"
    (cancelled)="cancelPublishing()"
    >
  </app-release-notes-editor>
}
@for (releaseNotes of releaseNotesList; track releaseNotes) {
  <div class="release-notes-item">
    @if (isEditing(releaseNotes)) {
      <app-release-notes-editor
        [releaseNotes]="releaseNotes"
        (published)="onPublish()"
        (cancelled)="cancelEditing()"
        >
      </app-release-notes-editor>
    }
    <app-release-notes-view
      [hidden]="isEditing(releaseNotes)"
      [releaseNotes]="releaseNotes"
      (editClicked)="startEditing(releaseNotes)"
      >
    </app-release-notes-view>
  </div>
}
