import memoize from '../../core/utils/memoize.mjs';
import { unitScale } from '../../core/utils/unit-scale.mjs';
import stringRepeat from '../../core/utils/string-repeat.mjs';
const baseCreateUnitScaleFromLocaleAbbreviations = str => {
  if (!str) {
    return unitScale({
      base: '',
      scale: {}
    });
  }
  const scale = str.split('|');
  const scaleDefinition = {
    ['']: 1
  };
  scale.forEach((scaleItem, scaleItemIndex) => {
    if (!scaleItem) return;
    scaleDefinition[scaleItem] = +(1 + stringRepeat('0', scaleItemIndex));
  });
  return unitScale({
    base: '',
    scale: scaleDefinition
  });
};
const createUnitScaleFromLocaleAbbreviations = memoize(baseCreateUnitScaleFromLocaleAbbreviations);
export default createUnitScaleFromLocaleAbbreviations;