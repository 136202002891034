import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { VERSION } from '../../../environments/version';
import { StatusService } from '../../status';
import { ReleaseNotesService } from '../../release-notes/release-notes.service';
import { BrandingService } from '../../shared/branding';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentYear: string;
  public version: string;
  public hover: string;

  constructor(
    private dialog: MatDialog,
    private statusService: StatusService,
    private releaseNotesService: ReleaseNotesService,
    private brandingService: BrandingService
  ) {}

  ngOnInit() {
    this.currentYear = DateTime.local().toFormat('yyyy');

    this.version = VERSION.version;
    this.hover = `UI: ${VERSION.version}-${VERSION.hash}`;

    this.getApiVersion();
  }

  getApiVersion(): void {
    this.statusService
      .getVersion()
      .pipe(first())
      .subscribe((apiVersion) => {
        this.hover += ` • API: ${apiVersion.build.version}-${apiVersion.git.commit.id}`;
      });
  }

  openReleaseNotes(): void {
    this.releaseNotesService.toggleVisibility();
  }

  get termsAndConditionsUrl(): string {
    return this.brandingService.termsAndConditionsUrl;
  }
}
