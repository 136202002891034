import { PaginationOptions } from '@comsig/core';

export class ReleaseNotesEnvelope {
  id: string;
  group: string;
  json: any;
}

export class ReleaseNotes {
  id: string;
  title: string;
  notes: string;
  categoryTrackerNotes: string;
  merchantTrackerNotes: string;
  releasedOn: string;
}

export interface ReleaseNotesOptions {
  group?: string;
}
