import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { HelpDataUsageComponent } from './help-data-usage/help-data-usage.component';
import { HelpRoutingModule } from './help-routing.module';

import { HelpIndexComponent } from './help-index/help-index.component';
import { HelpTocComponent } from './help-toc/help-toc.component';

@NgModule({
  imports: [LayoutModule, SharedModule, HelpRoutingModule],
  declarations: [HelpIndexComponent, HelpDataUsageComponent, HelpTocComponent],
  exports: [HelpIndexComponent],
})
export class HelpModule {}
