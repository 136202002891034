<h1 *ngxPermissionsOnly="['updateReleaseNotes']; else readOnly">
  <a href="javascript:;" (click)="startEditing()"> {{ releaseNotes.title }}</a>
</h1>
<ng-template #readOnly>
  <h1>{{ releaseNotes.title }}</h1>
</ng-template>

@if (releaseNotes?.releasedOn) {
  <div class="mat-caption">
    Released on {{ releaseNotes.releasedOn | date }}
  </div>
}
<div class="notes" [innerHTML]="notes | sanitizeHtml"></div>
