import { Component } from '@angular/core';
import { tables } from '../help.items';

@Component({
  selector: 'app-help-data-usage',
  templateUrl: './help-data-usage.component.html',
  styleUrl: './help-data-usage.component.scss',
})
export class HelpDataUsageComponent {
  public tables = tables;
}
