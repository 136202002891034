import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { ReleaseNotesService } from '../release-notes.service';
import { ReleaseNotes } from '../release-notes.model';

@Component({
  selector: 'app-release-notes-list',
  templateUrl: './release-notes-list.component.html',
  styleUrls: ['./release-notes-list.component.scss'],
})
export class ReleaseNotesListComponent implements OnInit {
  public releaseNotesList: ReleaseNotes[];
  public isPublishing = false;
  public isLoading = false;
  public releaseNotesToEdit: ReleaseNotes;

  constructor(private releaseNotesService: ReleaseNotesService) {}

  ngOnInit() {
    this.loadReleaseNotes();
  }

  loadReleaseNotes(): void {
    this.isLoading = true;
    this.releaseNotesService
      .getLatest()
      .pipe(first())
      .subscribe((releaseNotesList: ReleaseNotes[]) => {
        this.isLoading = false;
        this.releaseNotesList = releaseNotesList.sort((a, b) =>
          b.releasedOn.localeCompare(a.releasedOn)
        );

        this.releaseNotesService.countUnread();
      });
  }

  startPublishing(): void {
    this.cancelEditing();
    this.isPublishing = true;
  }

  cancelPublishing(): void {
    this.isPublishing = false;
  }

  startEditing(releaseNotes: ReleaseNotes): void {
    this.cancelPublishing();
    this.releaseNotesToEdit = releaseNotes;
  }

  isEditing(releaseNotes: ReleaseNotes): boolean {
    return this.releaseNotesToEdit === releaseNotes;
  }

  cancelEditing(): void {
    this.releaseNotesToEdit = null;
  }

  onPublish(): void {
    this.cancelPublishing();
    this.cancelEditing();
    this.loadReleaseNotes();
  }
}
