import { patternReplace } from '../utils/pattern-regexp-utils.mjs';
import numberToFormattedNumber from '../format/number-to-formatted-number/number-to-formatted-number.mjs';
const currencySymbolsMap = {
  EUR: '€',
  USD: '$',
  XCD: 'EC$',
  AUD: 'A$',
  INR: '₹',
  BRL: 'R$',
  CAD: 'CA$',
  XAF: 'FCFA',
  CNY: 'CN¥',
  NZD: 'NZ$',
  XPF: 'CFPF',
  GBP: '£',
  HKD: 'HK$',
  ILS: '₪',
  JPY: '¥',
  KRW: '₩',
  XOF: 'CFA',
  MXN: 'MX$',
  TWD: 'NT$',
  VND: '₫'
};
const currencyFormatter = {
  name: 'currency',
  regexps: {
    format: /(\$)/
  },
  format: (number, pattern, options) => {
    var _a;
    const currencyFromOptions = (_a = options.currency) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    const localizedCurrencySymbol = currencySymbolsMap[currencyFromOptions] || currencyFromOptions || '';
    const patternWithEscapedCurrencySymbol = patternReplace(pattern, /\$/, `'ɵcurrencyɵ'`);
    const formatResult = numberToFormattedNumber(number, patternWithEscapedCurrencySymbol, options);
    return formatResult.replace('ɵcurrencyɵ', _ => localizedCurrencySymbol);
  }
};
export default currencyFormatter;