Tables received from Avios via SFTP each month:

<ol class="flex" class="flex mt-2 gap-x-12 list-decimal ml-4">
  @for (table of tables; track table.name) {
    <li>{{ table.name }}</li>
  }
</ol>

@for (table of tables; track table.name) {
  <table class="mt-4">
    <tr>
      <th>Table: {{ table.name }}</th>
      <th>Used for dashboard</th>
    </tr>
    @for (column of table.columns; track column.name) {
      <tr>
        <td>
          {{ column.name }}
        </td>
        <td>
          {{ column.isForDashboard ? 'Y' : 'N' }}
        </td>
      </tr>
    }
  </table>
}
