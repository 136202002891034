import isFunction from './core/utils/is-function.mjs';
import merge from './core/utils/merge.mjs';
import isObject from './core/utils/is-object.mjs';
import isString from './core/utils/is-string.mjs';
import format$1 from './formatter/format/format.mjs';
function format(number, arg2, arg3) {
  const pattern = isString(arg2) ? arg2 : null;
  const options = isObject(arg2) ? arg2 : isObject(arg3) ? arg3 : {};
  return format$1(number, pattern, options);
}
const createFormatFunction = options => {
  const baseOptions = merge(options, {
    locale: isFunction(options.locale) ? options.locale() : options.locale
  });
  return (value, arg2, arg3) => {
    const pattern = isString(arg2) ? arg2 : null;
    const optionsFromArguments = isObject(arg2) ? arg2 : isObject(arg3) ? arg3 : {};
    return format$1(value, pattern, merge(baseOptions, optionsFromArguments));
  };
};
format.withOptions = createFormatFunction;
export default format;
export { createFormatFunction };