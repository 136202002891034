import isNaNNumber from '../../core/utils/is-nan-number.mjs';
import isFiniteNumber from '../../core/utils/is-finite-number.mjs';
import { patternReplace } from '../utils/pattern-regexp-utils.mjs';
import multiplyByPowerOfTen from '../../core/utils/multiply-by-power-of-ten.mjs';
import formattedStringToNumber from '../parse/utils/formatted-string-to-number.mjs';
import splitStringInTwoParts from '../../core/utils/split-string-in-two-parts.mjs';
import numberToFormattedNumber from '../format/number-to-formatted-number/number-to-formatted-number.mjs';
const exponentialFormatter = {
  name: 'exponential',
  regexps: {
    format: /[eE][+-][0-9]+/,
    unformat: /[eE][+-][0-9]+/
  },
  format: (number, pattern, options) => {
    const exponential = typeof number === 'number' && !isNaNNumber(number) ? number.toExponential() : '0e+0';
    const parts = splitStringInTwoParts(exponential, 'e');
    const patternWithoutExponential = patternReplace(pattern, /e[+|-]{1}0/i, '');
    const formatResult = numberToFormattedNumber(+parts[0], patternWithoutExponential, options);
    return formatResult + 'e' + parts[1];
  },
  unformat: (string, options) => {
    var _a;
    const value = formattedStringToNumber(string.replace(/e[+-]{1}[0-9]{1,3}/i, ''), options);
    const powerOfTenExponent = +(((_a = string.match(/e([+-]{1}[0-9]{1,3})/i)) === null || _a === void 0 ? void 0 : _a[1]) || '0');
    return isFiniteNumber(value) ? multiplyByPowerOfTen(value, powerOfTenExponent) : value;
  }
};
export default exponentialFormatter;