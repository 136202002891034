import isFiniteNumber from '../../../core/utils/is-finite-number.mjs';
import { patternStripAndNormalizeEscapedText } from '../../utils/pattern-regexp-utils.mjs';
import parsePattern from '../parse-pattern/parse-pattern.mjs';
import removeSignIfExists from './utils/remove-sign-if-exists.mjs';
import rescaleRoundedValue from './utils/rescale-rounded-value.mjs';
import scaleValueWithAbbreviation from './utils/scale-value-with-abbreviation.mjs';
import roundValueAndAddTrailingZeros from './utils/round-value-and-add-trailing-zeros.mjs';
import replaceDigitsWithNumeralSystem from './utils/replace-digits-with-numeral-system.mjs';
import addOrRemoveLeadingZerosToValue from './utils/add-or-remove-leading-zeros-to-value.mjs';
import addSignInfoToFullFormattedNumber from './utils/add-sign-info-to-full-formatted-number.mjs';
import splitNumberIntegerAndDecimalParts from './utils/split-number-integer-and-decimal-parts.mjs';
import addThousandsSeparatorToValueIntegerPart from './utils/add-thousands-separator-to-value-integer-part.mjs';
import applyAbbreviationLocalizedUnitToPatternMask from './utils/apply-abbreviation-localized-unit-to-pattern-mask.mjs';
const scaleAndRoundValue = (number, patternRules, options) => {
  // If it doesn't have abbreviation, just round the value and add trailing zeros
  if (!patternRules.compact) {
    const roundedValueAsString = roundValueAndAddTrailingZeros(number, patternRules, options);
    return [roundedValueAsString, null];
  }
  // If it has abbreviation, scales the value
  const [scaledValue, scaledValueLocalizedUnit] = scaleValueWithAbbreviation(number, patternRules, options);
  const roundedScaledValue = +roundValueAndAddTrailingZeros(scaledValue, patternRules, options);
  const [rescaledValue, rescaledValueLocalizedUnit] = rescaleRoundedValue(+roundedScaledValue, scaledValueLocalizedUnit, patternRules, options);
  const roundedRescaledValueAsStringWithTrailingZeros = roundValueAndAddTrailingZeros(rescaledValue, patternRules, options);
  return [roundedRescaledValueAsStringWithTrailingZeros, rescaledValueLocalizedUnit];
};
const numberToFormattedNumber = (number, pattern, options) => {
  var _a;
  const patternRules = parsePattern(pattern);
  // Ensure always uses a number or default number
  const resolvedValue = isFiniteNumber(number) ? number : 0;
  const [valueAsString, localizedAbbreviationUnit] = scaleAndRoundValue(resolvedValue, patternRules, options);
  // Prevents potentially wrong formatting coming from this function
  if (valueAsString === 'NaN') return '';
  const isValueNegative = options.signedZero ? number < 0 : +valueAsString < 0;
  const isValueZero = options.signedZero ? number === 0 : +valueAsString === 0;
  const valueAsStringWithoutSign = removeSignIfExists(valueAsString);
  const [integerPart, decimalPart] = splitNumberIntegerAndDecimalParts(valueAsStringWithoutSign, patternRules);
  const valueIntegerPartWithLeadingZeros = addOrRemoveLeadingZerosToValue(integerPart, patternRules);
  const valueIntegerPartWithThousandsSeparator = addThousandsSeparatorToValueIntegerPart(valueIntegerPartWithLeadingZeros, patternRules, options);
  const numeralSystemFromLocale = options.locale.numeralSystem;
  const integerPartWithNumeralSystem = replaceDigitsWithNumeralSystem(valueIntegerPartWithThousandsSeparator, numeralSystemFromLocale);
  const decimalPartWithNumeralSystem = replaceDigitsWithNumeralSystem(decimalPart, numeralSystemFromLocale);
  const fullNumberWithNumeralSystem = integerPartWithNumeralSystem + (!!decimalPartWithNumeralSystem ? (((_a = options.locale.delimiters) === null || _a === void 0 ? void 0 : _a.decimal) || '.') + decimalPartWithNumeralSystem : '');
  // Assembling
  const patternMaskWithAbbreviation = applyAbbreviationLocalizedUnitToPatternMask(patternRules.patternMask, localizedAbbreviationUnit, patternRules.compact);
  const patternMaskWithNumber = patternMaskWithAbbreviation.replace(`'ɵn'`, _ => `'${fullNumberWithNumeralSystem.replace(/'/g, "\\'")}'`);
  const patternMaskWithSignInfo = addSignInfoToFullFormattedNumber(patternMaskWithNumber, isValueNegative, isValueZero, patternRules);
  const cleanPatternMask = patternMaskWithSignInfo.replace(/'ɵ(nps|npe|s|a|n)'/g, '');
  const fullFormattedValueWithNormalizedText = patternStripAndNormalizeEscapedText(cleanPatternMask);
  return fullFormattedValueWithNormalizedText;
};
export default numberToFormattedNumber;