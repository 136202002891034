import { Injectable } from '@angular/core';
import { format } from 'numerable';

export interface FormatOptions {
  isCurrency?: boolean;
  isRate?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NumeralService {
  public currency;

  constructor() {}

  format(amount: number, formatPattern: string): string {
    const formattedNumber = format(Math.abs(amount), formatPattern, { currency: this.currency });

    return amount >= 0 ? formattedNumber : `-${formattedNumber}`;
  }

  formatMetric(amount: number, options: FormatOptions = {}): string {
    const prefix = options.isCurrency ? '$' : '';

    let decimals = '';
    if (options.isCurrency) {
      decimals = '.00';
    } else if (options.isRate) {
      decimals = '.0';
    }

    const suffix = options.isRate ? '%' : '';

    const formatPattern = `${prefix}0,0${decimals}`;

    return `${this.format(amount, formatPattern)}${suffix}`;
  }

  get currencySymbol(): string {
    switch (this.currency) {
      case 'GBP':
        return '£';

      default:
        return '$';
    }
  }
}
