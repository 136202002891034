@if (isLoading) {
  <div class="overlay-spinner-container">
    <mat-spinner> </mat-spinner>
  </div>
}
@if (partnerCategoryPerformance) {
  <div class="flex gap-x-4">
    <div class="chart-column">
      @if (!merchantChartErrors) {
        <app-home-performance-merchant-category-chart
          [chartTitle]="filters.partner.name"
          [metric]="metric"
          [amountKey]="amountKey"
          [populationSegment]="filters.populationSegment"
          [populationSegmentPerformance]="partnerCategoryPerformance.partner"
          [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
        ></app-home-performance-merchant-category-chart>
      } @else {
        <div class="placeholder">
          <app-content-placeholder-section>
            <div class="message">
              No partner data available for the selected {{ this.merchantChartErrors.join(' or ') }}
            </div>
          </app-content-placeholder-section>
        </div>
      }
    </div>
    <div class="chart-column">
      @if (!categoryChartErrors) {
        <app-home-performance-merchant-category-chart
          [chartTitle]="filters.partner.category.name"
          [metric]="metric"
          [amountKey]="amountKey"
          [populationSegment]="filters.populationSegment"
          [populationSegmentPerformance]="partnerCategoryPerformance.category"
          [hasCustomerData]="hasCustomerCategoryData"
          [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
        ></app-home-performance-merchant-category-chart>
      } @else {
        <div class="placeholder">
          <app-content-placeholder-section>
            <div class="message">
              No category data available for the selected {{ this.categoryChartErrors.join(' or ') }}
            </div>
          </app-content-placeholder-section>
        </div>
      }
    </div>
  </div>
}


