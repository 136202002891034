<div class="cdk-overlay-container" [class]="expandClass">
  <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
    <div class="panel-wrap" [class]="expandClass">
      <div class="panel flex flex-col">
        <app-dialog-header textAlign="left" (closeModal)="close()"> Updates </app-dialog-header>
        <div class="inner-container">
          <app-release-notes-list></app-release-notes-list>
        </div>
      </div>
    </div>
  </div>
</div>
