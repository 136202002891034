import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { FileSaverService } from 'ngx-filesaver';

import { Dataset, DateRange } from '../../shared/common-api';
import { Filters, FiltersService } from '../../shared/filters';
import { HomeService, Metric, PerformanceApiParams } from '../';
import { metrics } from '../home.items';

@UntilDestroy()
@Component({
  selector: 'app-home-performance',
  templateUrl: './home-performance.component.html',
  styleUrls: ['./home-performance.component.scss'],
})
export class HomePerformanceComponent implements OnInit {
  @Input() dataset: Dataset;

  public filters: Filters;
  public metrics: Metric[] = metrics;
  public metric: Metric;
  public dateRange: DateRange;
  public amountKeys = ['totals', 'deltas'];
  public amountKey: string;

  public tabs = ['Totals', 'Percent Change YoY'];

  constructor(
    private fileSaverService: FileSaverService,
    private filtersService: FiltersService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.amountKey = this.amountKeys[0];
    this.metric = this.metrics[1];

    this.filtersService.filters$
      .pipe(untilDestroyed(this))
      .subscribe((trackerFiltersUpdate) => this.onSharedFilterChange(trackerFiltersUpdate));
  }

  tabChanged(index: number): void {
    this.amountKey = this.amountKeys[index];
  }

  get isNonCustomerTotalPopulation(): boolean {
    return this.filtersService.isNonCustomerTotalPopulation(this.filters);
  }

  get isReady(): boolean {
    return this.filters !== undefined && this.dateRange !== undefined;
  }

  onDateRangeChange(event: DateTime[]): void {
    this.dateRange = {
      startOn: event[0],
      endOn: event[1],
    };
  }

  onSharedFilterChange(filters: Filters): void {
    if (filters === null) {
      return;
    }

    this.filters = filters;
  }

  download(): void {
    const params: PerformanceApiParams = {
      ...this.filtersService.paramsFrom(this.filters),
      startOn: this.dateRange.startOn.toISODate(),
      endOn: this.dateRange.endOn.toISODate(),
    };

    const filename = this.filtersService.exportFilename(
      `insights_export_performance_${params.startOn}-${params.endOn}`,
      params
    );

    this.homeService
      .exportPerformance(params)
      .subscribe((response) => this.fileSaverService.save(response.body, `${filename}.xlsx`));
  }

  get subtitle(): string {
    if (!this.filters) {
      return '';
    }

    let typeLabel = this.filters.customerTypeId;

    switch (this.filters.customerTypeId) {
      case 'PARTNER':
        typeLabel = 'Customers who have collected at partner';
        break;
      case 'ACTIVE':
        typeLabel = 'Avios active customers';
        break;
      case 'INACTIVE':
        typeLabel = 'Avios inactive customers';
        break;
      case 'NON_PARTNER':
        typeLabel = 'Customers who have not collected at partner';
        break;
    }

    return `${typeLabel} by ${this.filters.populationSegment.name}`;
  }

  get hasCustomerCategoryData(): boolean {
    return !['PARTNER', 'NON_PARTNER'].includes(this.filters.customerTypeId);
  }
}
