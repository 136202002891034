import multiplyByPowerOfTen from '../../../core/utils/multiply-by-power-of-ten.mjs';
import log10 from '../../../core/utils/log-10.mjs';
import toObject from '../../../core/utils/to-object.mjs';
import escapeRegexString from '../../../core/utils/escape-regex-string.mjs';
import createUnitScaleFromLocaleAbbreviations from '../../utils/create-unit-scale-from-locale-abbreviations.mjs';
const replaceNumeralSystemWithLatinNumbers = (numericStringWithExtraInfo, numeralSystemMap) => {
  if (!numeralSystemMap || numeralSystemMap.length !== 10) return numericStringWithExtraInfo;
  const numericStringLength = numericStringWithExtraInfo.length;
  const numeralSystemToLatinSystemMap = toObject(numeralSystemMap, (digit, digitIndex) => [digit.replace(/\u200e/g, ''), '' + digitIndex]);
  let output = '';
  for (let numericStringIndex = 0; numericStringIndex < numericStringLength; numericStringIndex++) {
    const char = numericStringWithExtraInfo[numericStringIndex];
    output += numeralSystemToLatinSystemMap[char] || char;
  }
  return output;
};
const getScalingFactorFromAbbreviations = (stringOriginal, options) => {
  var _a;
  const scale = createUnitScaleFromLocaleAbbreviations((_a = options.locale) === null || _a === void 0 ? void 0 : _a.abbreviations);
  const abbreviationsSortedByLengthDesc = Object.keys(scale.scaleDefinition.scale).sort((a, b) => b.length - a.length);
  let abbreviationScalingFactor = 1;
  for (const abbreviation of abbreviationsSortedByLengthDesc) {
    const scapedAbbreviationForRegex = escapeRegexString(abbreviation);
    const regexp = new RegExp('[^a-zA-Z]' + `(${scapedAbbreviationForRegex})|(${scapedAbbreviationForRegex.replace(/\u200e/g, '')})` + '(?:\\)(?:\\))?)?$');
    if (stringOriginal.match(regexp)) {
      abbreviationScalingFactor = scale.toBase(abbreviationScalingFactor, abbreviation);
      break;
    }
  }
  return abbreviationScalingFactor;
};
// unformats numbers separators, decimals places, signs, abbreviations
const formattedStringToNumber = (inputString, options) => {
  var _a;
  const locale = options.locale;
  const stringOriginal = inputString;
  let value;
  // Replace special digits with latin digits
  const stringWithLatinDigits = replaceNumeralSystemWithLatinNumbers(inputString, (_a = options.locale) === null || _a === void 0 ? void 0 : _a.numeralSystem);
  if (options.zeroFormat && stringWithLatinDigits === options.zeroFormat) {
    value = 0;
  } else if (options.nullFormat && stringWithLatinDigits === options.nullFormat || !stringWithLatinDigits.replace(/[^0-9]+/g, '').length) {
    value = null;
  } else {
    // Replaces the locale decimal delimiter with a dot (.)
    const decimalDelimiterFromLocale = locale.delimiters.decimal;
    const stringWithDotDecimalDelimiter = decimalDelimiterFromLocale === '.' ? stringWithLatinDigits : stringWithLatinDigits.replace(/\./g, '').replace(decimalDelimiterFromLocale, '.');
    // Determines the scaling factor from the abbreviations (if has abbreviations)
    const abbreviationScalingFactor = getScalingFactorFromAbbreviations(stringOriginal, options);
    // Check for negative number
    const negativeFactor = (stringWithDotDecimalDelimiter.split('-').length + Math.min(stringWithDotDecimalDelimiter.split('(').length - 1, stringWithDotDecimalDelimiter.split(')').length - 1)) % 2 ? 1 : -1;
    // Remove non numbers
    const numberAsString = stringWithDotDecimalDelimiter.replace(/[^0-9.]+/g, '');
    value = negativeFactor * multiplyByPowerOfTen(+numberAsString, log10(abbreviationScalingFactor));
  }
  return value;
};
export default formattedStringToNumber;