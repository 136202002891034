<app-basic-page [shadeBackground]="true">
  <a class="back" routerLink="/">
    <i class="cs cs-chevron-left"></i>
    Back to dashboard
  </a>
  <h1>Help</h1>
  <div class="flex gap-x-20">
    <div class="w-1/3">
      <app-help-toc></app-help-toc>
    </div>
    <div class="w-3/4">
      <div class="disclaimer disclaimer-top">
        This dashboard is for internal use only please speak to the DPO if you want to use this to
        speak to any of our partners.
      </div>

      <h2 id="introduction">Introduction</h2>
      <div class="help-section">
        <strong>{{ productTitle }}</strong> Dashboard fills in the blanks between how a customer of
        your retail partner who spends on their credit cards interacts with the Avios programme
        making it easy to evaluate performance of existing and new retail partners to understand
        what opportunity there is in getting more customers to collect.
      </div>

      <h2 id="data_capture">Data Source</h2>
      <div class="help-section">
        <ul class="definitions">
          <li><strong>Spending Data:</strong> UK Credit Card Spending Data <i>from Argus</i></li>
          <li>
            <strong>Avios Customer Data:</strong> British Airways Avios Customer Monthly Data
            <i>from Avios</i>
          </li>
        </ul>
      </div>

      <h2 id="data_refresh_cadence">Data Refresh Cadence</h2>
      <div class="help-section">
        <ul class="definitions">
          <li>
            <strong>Time Window:</strong> Refreshed monthly with monthly spend trends starting Jan
            2019
          </li>
          <li>
            <strong>Timelines:</strong> Refreshed with a 60 day lag
            <i>e.g. data refreshed in Jan 2021 will be to Nov 2020</i>
          </li>
        </ul>
      </div>

      <h2 id="trip">{{ productTitle }}</h2>
      <div class="help-section">
        <h3>Features</h3>

        <ul class="definitions">
          <li><strong>Location Granularity:</strong> UK Customers</li>

          <li>
            <strong>Partner:</strong> Avios Partner List (31 Partners – already agreed) -- scroll
            below for more details
          </li>

          <li><strong>Comparison Category:</strong> Based on Sic Group on the transaction</li>

          <li><strong>Month of Transaction:</strong> Based on transaction date with retailer</li>

          <li>
            <strong>Metrics:</strong>
          </li>

          <ul class="definitions child">
            <li><strong>Sales Value £:</strong> Total Amount of Net Sales for All Transactions</li>

            <li><strong>Transaction Volume #:</strong> Total number of Transactions</li>

            <li><strong>Number of Customers #:</strong> Total number of Customers</li>

            <li><strong>Average Spend £:</strong> Total Sales Value / Total number of Customers</li>

            <li>
              <strong>Average Basket Size £:</strong> Total Sales Value/ Total number of
              Transactions
            </li>

            <li>
              <strong>Uplift Index:</strong> Average Spend per Avios Customer / Average Spend per
              Non-Avios Customer
            </li>
          </ul>

          <li>
            <strong>Customer Population</strong> - UK British Airways Executive Club members which
            can be filtered by:
          </li>

          <ul class="definitions child">
            <li>
              <strong>Avios Active Customers:</strong> Avios customers who have spent at partner and
              are active with Avios in the date range
            </li>
            <li>
              <strong>Avios Inactive Customers:</strong> Avios customers who have spent at partner
              but who are inactive with Avios in the date range
            </li>
            <li>
              <strong>Collected at Partner:</strong> Avios customers who have spent at partner
              <strong>and</strong> have collected Avios with the partner in the date range
            </li>
            <li>
              <strong>Not Collected at Partner:</strong> Avios customers who have spent at partner
              but who have not collected Avios with the partner in the date range
            </li>
            <li>
              <strong>Non-Avios Customers:</strong> People who have spent at the partner, but who
              are not Avios customers
            </li>
          </ul>

          <li>
            <strong>Population Segments:</strong>
          </li>

          <ul class="definitions child">
            <li>
              <strong>Avios Points Balance:</strong> Avios balance defined as the Avios points
              balance for the month selected
              <ul>
                <li>High - Avios points balance for month of analysis >= 40,000</li>
                <li>Medium - Avios points balance for month of analysis >= 5,000 and < 40,000</li>
                <li>Low - Avios points balance for month of analysis > 0 and < 5,000</li>
                <li>None - <= 0</li>
              </ul>
            </li>

            <li>
              <strong>Avios Tier:</strong> Tier defined as the tier each customer falls under for
              the month selected
              <ul>
                <li>Blue</li>
                <li>Bronze</li>
                <li>Silver</li>
                <li>Gold</li>
              </ul>
            </li>

            <li>
              <strong>Loyalty Segment:</strong>
              <ul>
                <li>Group 1 – Dormant, False Starters, Lapsed, Churn, Onboarding/Educate</li>
                <li>Group 2 – Stagnant Collectors, Super Savers, Collection Growth (All)</li>
                <li>Group 3 – Redeemers, Collect to Redeem Again (All)</li>
                <li>Group 4 – High Engagers</li>
              </ul>
            </li>

            <li>
              <strong>Monthly Customer Wallet Spend:</strong> Total monthly customer spend across
              all credit cards in wallet
              <ul>
                <li>> £1,000</li>
                <li>£350 – £1,000</li>
                <li>£100 – £350</li>
                <li>< £100</li>
              </ul>
            </li>
          </ul>

          <li>
            <strong>Retail Partners and Comparison Categories:</strong>
          </li>

          <table>
            <tr>
              <th>Retail Partner Name</th>
              <th>Category</th>
              <th>Top Merchants for Category</th>
              <th>Mapping</th>
            </tr>
            @for (partner of partners; track partner) {
              <tr>
                <td>
                  {{ partner.name }}
                </td>
                <td>
                  {{ partner.category.name }}
                </td>
                <td>
                  {{ partner.category.topMerchants }}
                </td>
                <td>
                  {{ partner.mapping }}
                </td>
              </tr>
            }
          </table>
        </ul>
      </div>

      <div class="help-section" id="data_usage">
        <h3>Avios Data Usage</h3>
        <app-help-data-usage></app-help-data-usage>
      </div>

      <div class="help-section" id="contact">
        <h3>Contact for More Information</h3>
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
      </div>

      <div class="disclaimer">
        This dashboard is for internal use only please speak to the DPO if you want to use this to
        speak to any of our partners.
      </div>
    </div>
  </div>
</app-basic-page>
