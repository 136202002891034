import { Injectable } from '@angular/core';
import { TrackJSSettings } from '@comsig/core';
import { TrackJS } from 'trackjs';

@Injectable({
  providedIn: 'root',
})
export class TrackJSService {
  init(settings: TrackJSSettings) {
    if (settings.enabled === false) {
      return;
    }

    TrackJS.install({
      token: settings.token,
      application: settings.application,
    });
  }
}
