import { patternReplace } from '../utils/pattern-regexp-utils.mjs';
import numberToFormattedNumber from '../format/number-to-formatted-number/number-to-formatted-number.mjs';
const ordinalFormatter = {
  name: 'ordinal',
  regexps: {
    format: /o/
  },
  format: (number, pattern, options) => {
    var _a, _b;
    const localizedOrdinal = ((_b = (_a = options.locale).ordinal) === null || _b === void 0 ? void 0 : _b.call(_a, number)) || '';
    const patternWithEscapedOrdinal = patternReplace(pattern, /o/, `'ɵordɵ'`);
    const formatResult = numberToFormattedNumber(number, patternWithEscapedOrdinal, options);
    return formatResult.replace('ɵordɵ', _ => localizedOrdinal);
  }
};
export default ordinalFormatter;