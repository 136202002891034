<footer class="flex justify-center items-center gap-x-4">
  <span>&copy; {{ currentYear }} TransUnion | Commerce Signals</span>
  @if (termsAndConditionsUrl) {
    <span>○</span>
    <span>
      <a
        href="https://www.commercesignals.com/dashboard-terms/"
        rel="noopener noreferrer"
        target="_blank"
        >
        Terms and Conditions
      </a>
    </span>
  }
  <span>○</span>
  <span title="{{ hover }}"> v{{ version }} </span>
</footer>
