import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from '@comsig/core';

export interface Permissions {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private authService: AuthService
  ) {}

  setupPermissions(): void {
    this.ngxPermissionsService.flushPermissions();

    const permissions: Permissions = {
      viewAdmin: this.authService.authUserHasRole('ROLE_ADMIN'),
    };

    this.ngxPermissionsService.addPermission(
      Object.keys(permissions).filter((key) => permissions[key])
    );
  }

  hasPermission(name: string): boolean {
    return this.ngxPermissionsService.getPermission(name) !== undefined;
  }
}
