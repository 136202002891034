import isFiniteNumber from '../../core/utils/is-finite-number.mjs';
import { patternReplace } from '../utils/pattern-regexp-utils.mjs';
import multiplyByPowerOfTen from '../../core/utils/multiply-by-power-of-ten.mjs';
import formattedStringToNumber from '../parse/utils/formatted-string-to-number.mjs';
import numberToFormattedNumber from '../format/number-to-formatted-number/number-to-formatted-number.mjs';

/**
 * Basis point format (BPS)
 * <i> See https://en.wikipedia.org/wiki/Basis_point
 */
const bpsFormatter = {
  name: 'bps',
  regexps: {
    format: /BPS/,
    unformat: /BPS/
  },
  format: (number, pattern, options) => {
    const scaledValue = multiplyByPowerOfTen(number, 4);
    const patternWithEscapedBPS = patternReplace(pattern, /BPS/, `'ɵBPSɵ'`);
    const formatResult = numberToFormattedNumber(scaledValue, patternWithEscapedBPS, options);
    return formatResult.replace('ɵBPSɵ', 'BPS');
  },
  unformat: (string, options) => {
    const number = formattedStringToNumber(string.replace(/\s?BPS/, ''), options);
    return isFiniteNumber(number) ? multiplyByPowerOfTen(number, -4) : number;
  }
};
export default bpsFormatter;