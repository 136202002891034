import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BrandingService } from '../../shared/branding';

interface HelpTocSection {
  title: string;
  key: string;
  children?: HelpTocSection[];
}

@Component({
  selector: 'app-help-toc',
  templateUrl: './help-toc.component.html',
  styleUrls: ['./help-toc.component.scss'],
})
export class HelpTocComponent {
  public sections: HelpTocSection[];

  constructor(
    private router: Router,
    private brandingService: BrandingService
  ) {
    this.sections = [
      { title: 'Introduction', key: 'introduction' },
      { title: 'Data Capture', key: 'data_capture' },
      { title: 'Data Refresh Cadence', key: 'data_refresh_cadence' },
      { title: this.brandingService.title, key: 'trip' },
      { title: 'Avios Data Usage', key: 'data_usage' },
      {
        title: 'Contact for More Information',
        key: 'contact',
      },
    ];
  }

  selectSection(section: HelpTocSection): void {
    this.router.navigate(['/help'], { fragment: section.key });
  }
}
