import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { NumeralService } from '../../shared/numeral';
import { CommonApiService, Dataset } from '../../shared/common-api';

@Component({
  selector: 'app-home-index',
  templateUrl: './home-index.component.html',
  styleUrls: ['./home-index.component.scss'],
})
export class HomeIndexComponent implements OnInit {
  public dataset: Dataset;

  constructor(private numeralService: NumeralService, private commonApiService: CommonApiService) {}

  ngOnInit(): void {
    this.retrieveDataset();
  }

  retrieveDataset(): void {
    this.commonApiService
      .dataset()
      .pipe(first())
      .subscribe((dataset: Dataset) => {
        this.dataset = dataset;

        this.numeralService.currency = this.dataset.currency ?? 'GBP';
      });
  }
}
