@if (dataset) {
  <div>
    <app-filters></app-filters>
    <app-basic-page>
      <div class="home-block">
        <app-home-kpis></app-home-kpis>
      </div>
      <div class="home-block">
        <app-home-performance [dataset]="dataset"></app-home-performance>
      </div>
    </app-basic-page>
  </div>
} @else {
  <mat-spinner class="spinner"> </mat-spinner>
}
