import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@comsig/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from '@comsig/core';

import { ReleaseNotesService } from '../../../release-notes/release-notes.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  public name = '';

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private releaseNotesService: ReleaseNotesService,
    private appSettings: AppSettingsService
  ) {}

  ngOnInit() {
    this.name = `${this.authService.authUser.firstName} ${this.authService.authUser.lastName}`;
  }

  public logout() {
    this.authService.signOut(this.appSettings.getSettings().ping.logoutUri);
  }

  openReleaseNotes(): void {
    this.releaseNotesService.updateVisibility(true);
  }

  get $unreadUpdatesCount(): Observable<number> {
    return this.releaseNotesService.unreadUpdatesCount;
  }
}
