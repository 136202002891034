import stringRepeat from '../../../../core/utils/string-repeat.mjs';

/**
 * <i> Add or remove leading zeros
 * Expects: a value integer part as string, without the minus sign. And nothing else but a number at the start.
 * Returns:
 *     - The value with the added or removed leading zeros
 */
const addOrRemoveLeadingZerosToValue = (valueIntegerPartWithoutSign, patternRules) => {
  const {
    minimumIntegerDigits
  } = patternRules;
  if (minimumIntegerDigits === 0 && +valueIntegerPartWithoutSign < 1 && +valueIntegerPartWithoutSign > -1) {
    return '';
  }
  return valueIntegerPartWithoutSign.length >= minimumIntegerDigits ? valueIntegerPartWithoutSign : `${stringRepeat('0', minimumIntegerDigits - valueIntegerPartWithoutSign.length)}${valueIntegerPartWithoutSign}`;
};
export default addOrRemoveLeadingZerosToValue;