import { Metric } from './home.model';

export const metrics: Metric[] = [
  {
    id: 'transactors',
    name: 'Number of Customers',
    isCurrency: false,
    isAbbreviated: true,
  },
  {
    id: 'sales',
    name: 'Sales Value',
    isCurrency: true,
    isAbbreviated: true,
  },
  {
    id: 'transactions',
    name: 'Transaction Volume',
    isCurrency: false,
    isAbbreviated: true,
  },
  {
    id: 'avgSpend',
    name: 'Average Spend',
    infoText: 'Sales Value / Number of Customers',
    isCurrency: true,
    isAbbreviated: true,
  },
  {
    id: 'avgBasketSize',
    name: 'Average Basket Size',
    infoText: 'Sales Value / Transaction Volume',
    isCurrency: true,
    isAbbreviated: true,
  },
];
