<mat-card>
  <mat-card-content>
    <form (ngSubmit)="publish()" [formGroup]="form" novalidate class="flex flex-col">
      <mat-form-field class="flex-1">
        <input matInput formControlName="title" placeholder="Enter version / title" required />
        <mat-error> Enter a valid title </mat-error>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <mat-label>Choose a release date</mat-label>
        <input matInput formControlName="releasedOn" [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <textarea
          matInput
          formControlName="notes"
          placeholder="Enter general summary..."
          matTextareaAutosize
          matAutosizeMinRows="5"
        ></textarea>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <textarea
          matInput
          formControlName="categoryTrackerNotes"
          placeholder="Enter Category Tracker summary..."
          matTextareaAutosize
          matAutosizeMinRows="5"
        ></textarea>
      </mat-form-field>
      <mat-form-field class="flex-1">
        <textarea
          matInput
          formControlName="merchantTrackerNotes"
          placeholder="Enter Merchant Tracker summary..."
          matTextareaAutosize
          matAutosizeMinRows="5"
        ></textarea>
      </mat-form-field>
      <div class="action-buttons">
        @if (isEditing) {
          <button
            mat-stroked-button
            type="button"
            color="warn"
            (click)="delete()"
            [disabled]="isWorking"
            >
            Delete
          </button>
        }
        <div class="flex-1"></div>
        <button mat-button type="button" (click)="cancel()">Cancel</button>
        <button mat-button type="button" (click)="preview()">Preview</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="isWorking">
          Publish
        </button>
      </div>
      @if (previewReleaseNotes) {
        <div class="preview">
          <div class="mat-divider"></div>
          <h4>Preview:</h4>
          <app-release-notes-view [releaseNotes]="previewReleaseNotes"> </app-release-notes-view>
        </div>
      }
    </form>
  </mat-card-content>
</mat-card>
