@if (filters && timeframe) {
  <mat-card>
    <mat-card-content>
      <div class="flex">
        <h1 class="flex-1">
          {{ filters.partner.name }} Overall Performance from
          {{ timeframe?.displayStartOn | date: 'MMM yyyy' }} to
          {{ timeframe?.displayEndOn | date: 'MMM yyyy' }}
        </h1>
        <div>
          <div class="flex gap-x-4 justify-start items-center">
            <mat-form-field class="transparent-form-field">
              <mat-label>Select Time Period</mat-label>
              <mat-select [(ngModel)]="timeframe" (ngModelChange)="retrieveKpis()">
                @for (timeframe of timeframes; track timeframe) {
                  <mat-option [value]="timeframe">
                    {{ timeframe.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <button
              mat-icon-button
              matTooltip="Download data to Excel"
              matTooltipPosition="above"
              (click)="download()"
            >
              <mat-icon matSuffix class="cs cs-file-download"></mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="customers-chart flex">
        <div class="w-1/5">
          <app-home-kpis-metric
            [kpis]="kpis"
            [metric]="metrics[0]"
            [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
            layout="column"
            [isLoading]="isLoading"
            [hasUplift]="false"
          ></app-home-kpis-metric>
        </div>
        <div class="w-4/5">
          <app-home-kpis-customers-chart
            [filters]="filters"
            [timeframe]="timeframe"
            [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
          ></app-home-kpis-customers-chart>
        </div>
      </div>
      <div class="flex gap-x-4">
        @for (metric of metrics.slice(1, 3); track metric) {
          <div class="w-1/2">
            <app-home-kpis-metric
              [kpis]="kpis"
              [metric]="metric"
              [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
              [hasUplift]="false"
              [isLoading]="isLoading"
            ></app-home-kpis-metric>
          </div>
        }
      </div>
      <div class="flex gap-x-4">
        @for (metric of metrics.slice(3, 5); track metric) {
          <div class="w-1/2">
            <app-home-kpis-metric
              [kpis]="kpis"
              [metric]="metric"
              [isNonCustomerTotalPopulation]="isNonCustomerTotalPopulation"
              [isLoading]="isLoading"
            ></app-home-kpis-metric>
          </div>
        }
      </div>
      @if (isNonCustomerTotalPopulation) {
        <div class="tip">* Non Avios customers value reflects total Non-Avios Population</div>
      }
    </mat-card-content>
  </mat-card>
}
