@if (isLoading) {
  <div class="overlay-spinner-container">
    <mat-spinner> </mat-spinner>
  </div>
}
<div class="chart-container">
  <h3>Comparison to other Customer Populations</h3>
  @if (chartOptions) {
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      (chartInstance)="onChartCreation($event)"
      style="width: 100%; display: block"
      >
    </highcharts-chart>
  }
</div>
