import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, ApiEntity } from '@comsig/core';

import {
  CustomerPopulationCounts,
  Kpis,
  KpisApiParams,
  PartnerCategoryPerformance,
  PerformanceApiParams,
  PerformanceTrendline,
  Timeframe,
} from './';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends ApiEntity<any> {
  constructor(protected override api: ApiService) {
    super(api);
  }

  kpiTimeframes(): Observable<Timeframe[]> {
    return this.api.get('kpis/timeframes');
  }

  kpis(params: KpisApiParams): Observable<Kpis> {
    return this.api.get('kpis', params);
  }

  partnerCategoryPerformance(params: PerformanceApiParams): Observable<PartnerCategoryPerformance> {
    return this.api.get('partner-category-performance', params);
  }

  activeCustomers(params: KpisApiParams): Observable<CustomerPopulationCounts> {
    return this.api.get('active-customers', params);
  }

  performanceTrendline(params: PerformanceApiParams): Observable<PerformanceTrendline> {
    return this.api.get('trendline', params);
  }

  exportKpis(params: KpisApiParams): Observable<HttpResponse<Blob>> {
    return this.api.getBlob('exports/kpis', params);
  }

  exportPerformance(params: PerformanceApiParams): Observable<HttpResponse<Blob>> {
    return this.api.getBlob('exports/performance-breakdowns', params);
  }
}
