import { Injectable } from '@angular/core';

import { LogoImage } from './';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  constructor() {}

  get title(): string {
    return 'Partner Spend Insights';
  }

  get logoImage(): LogoImage {
    return {
      path: 'assets/images/comsig-logo-white.png',
      height: 33,
    };
  }

  get termsAndConditionsUrl(): string | null {
    return 'https://www.commercesignals.com/dashboard-terms/';
  }

  get supportEmail(): string {
    return 'support@commercesignals.com';
  }

  get isAvios(): boolean {
    return true;
  }
}
