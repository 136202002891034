import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ReleaseNotes } from '../release-notes.model';
import { PermissionsService } from '../../shared/permissions';

@Component({
  selector: 'app-release-notes-view',
  templateUrl: './release-notes-view.component.html',
  styleUrls: ['./release-notes-view.component.scss'],
})
export class ReleaseNotesViewComponent implements OnInit {
  @Input() public releaseNotes: ReleaseNotes;

  @Output() private editClicked: EventEmitter<boolean> = new EventEmitter();

  public notes: string;

  constructor(private permissionsService: PermissionsService) {}

  ngOnInit() {
    this.notes = this.formatNotes();
  }

  startEditing() {
    this.editClicked.emit();
  }

  formatNotes(): string {
    let releaseNotes = this.releaseNotes.notes ? this.releaseNotes.notes : '';

    if (
      this.permissionsService.hasPermission('viewCategoryTracker') &&
      this.releaseNotes.categoryTrackerNotes
    ) {
      releaseNotes += `<br>${this.releaseNotes.categoryTrackerNotes}`;
    }

    if (
      this.permissionsService.hasPermission('viewMerchantTracker') &&
      this.releaseNotes.merchantTrackerNotes
    ) {
      releaseNotes += `<br>${this.releaseNotes.merchantTrackerNotes}`;
    }

    return releaseNotes;
  }
}
