import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { ReleaseNotes } from '../release-notes.model';
import { ReleaseNotesService } from '../release-notes.service';
import { ConfirmDialogService } from '../../shared/confirm-dialog';

@Component({
  selector: 'app-release-notes-editor',
  templateUrl: './release-notes-editor.component.html',
  styleUrls: ['./release-notes-editor.component.scss'],
})
export class ReleaseNotesEditorComponent implements OnInit {
  @Input() public releaseNotes: ReleaseNotes = new ReleaseNotes();

  @Output() private published: EventEmitter<boolean> = new EventEmitter();
  @Output() private cancelled: EventEmitter<boolean> = new EventEmitter();

  public form: UntypedFormGroup;
  public previewReleaseNotes: ReleaseNotes;
  public isWorking = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private confirmDialog: ConfirmDialogService,
    private releaseNotesService: ReleaseNotesService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: [this.releaseNotes.title, Validators.required],
      notes: this.releaseNotes.notes,
      categoryTrackerNotes: this.releaseNotes.categoryTrackerNotes,
      merchantTrackerNotes: this.releaseNotes.merchantTrackerNotes,
      releasedOn: [
        this.isEditing ? DateTime.fromISO(this.releaseNotes.releasedOn).toJSDate() : new Date(),
        Validators.required,
      ],
    });
  }

  delete(): void {
    if (this.isWorking === true) {
      return;
    }

    const message = 'Are you sure you want to delete these release notes?';

    this.confirmDialog
      .open({ width: '500px', title: 'Delete Release Notes', message })
      .subscribe(response => {
        if (response) {
          this.isWorking = true;

          this.releaseNotesService
            .delete(this.releaseNotes)
            .pipe(first())
            .subscribe(() => {
              this.published.emit();
            });
        }
      });
  }

  publish(): void {
    if (this.isWorking || this.form.invalid) {
      return;
    }

    this.isWorking = true;

    this.releaseNotesService
      .save(this.currentInput)
      .pipe(first())
      .subscribe(() => {
        this.published.emit();
      });
  }

  preview(): void {
    this.previewReleaseNotes = this.currentInput;
  }

  cancel(): void {
    this.cancelled.emit();
  }

  get currentInput(): ReleaseNotes {
    return {
      id: this.releaseNotes.id,
      title: this.form.get('title').value,
      notes: this.form.get('notes').value,
      categoryTrackerNotes: this.form.get('categoryTrackerNotes').value,
      merchantTrackerNotes: this.form.get('merchantTrackerNotes').value,
      releasedOn: DateTime.fromJSDate(this.form.get('releasedOn').value).toISODate(),
    };
  }

  get isEditing(): boolean {
    return this.releaseNotes.id !== undefined;
  }
}
