import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ReleaseNotesService } from './release-notes.service';

import { ReleaseNotesListComponent } from './release-notes-list/release-notes-list.component';
import { ReleaseNotesEditorComponent } from './release-notes-editor/release-notes-editor.component';
import { ReleaseNotesViewComponent } from './release-notes-view/release-notes-view.component';
import { ReleaseNotesPanelComponent } from './release-notes-panel/release-notes-panel.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [ReleaseNotesService],
  declarations: [
    ReleaseNotesPanelComponent,
    ReleaseNotesEditorComponent,
    ReleaseNotesListComponent,
    ReleaseNotesViewComponent,
  ],
  exports: [ReleaseNotesListComponent, ReleaseNotesPanelComponent],
})
export class ReleaseNotesModule {}
