import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { pingAuthGuard } from '@comsig/core';

import { HelpIndexComponent } from './help-index/help-index.component';

const routes: Routes = [
  {
    path: 'help',
    component: HelpIndexComponent,
    canActivate: [pingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HelpRoutingModule {}
