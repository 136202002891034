<div class="metric-container" [class]="layout">
  <h2>
    {{ metric.name }}
    @if (metric.infoText) {
      <i
        class="cs cs-help-outline info-icon"
        matTooltipPosition="above"
        [matTooltip]="metric.infoText"
      ></i>
    }
  </h2>

  @if (!isLoading) {
    <div [class.flex-col]="layout === 'column'" class="inner-tiles flex">
      <div [class]="widthClass" class="inner-tile customers">
        <div class="subtitle">Avios Customers</div>
        <div
          class="amount"
          matTooltipPosition="above"
          [matTooltip]="customerTooltip"
          [attr.data-value]="kpiMetric.customers"
        >
          {{ customerAmount || '--' }}
        </div>
      </div>
      <div
        [class]="widthClass"
        class="inner-tile non-customers"
        [class.disabled]="isNonCustomerTotalPopulation"
      >
        <div class="subtitle">
          Non-Avios Customers{{ isNonCustomerTotalPopulation ? ' *' : '' }}
        </div>
        <div
          class="amount"
          matTooltipPosition="above"
          [matTooltip]="nonCustomerTooltip"
          [attr.data-value]="kpiMetric.nonCustomers"
        >
          {{ nonCustomerAmount || '--' }}
        </div>
      </div>
      @if (hasUplift) {
        <div [class]="widthClass" class="inner-tile uplift">
          <div class="subtitle">
            Uplift Index
            <i
              class="cs cs-help-outline info-icon"
              [matTooltip]="upliftTooltip"
              matTooltipPosition="above"
            ></i>
          </div>
          <div
            class="amount"
            matTooltipPosition="above"
            [matTooltip]="indexTooltip"
            [attr.data-value]="kpiMetric.index"
          >
            @if (kpis.avgSpend.index) {
              <span> {{ kpiMetric.index | number: '1.2-2' }}</span>
            } @else {
              <span>--</span>
            }
          </div>
        </div>
      }
    </div>
  } @else {
    <mat-spinner class="spinner"></mat-spinner>
  }
</div>
