import { patternRemoveEscapedText } from '../../../utils/pattern-regexp-utils.mjs';
import stringIncludes from '../../../../core/utils/string-includes.mjs';
import splitStringInTwoParts from '../../../../core/utils/split-string-in-two-parts.mjs';
const countChars = (string, char) => {
  return !string ? 0 : string.split('').filter(stringChar => stringChar === char).length;
};
/**
 * Fraction digits (decimals) count rule (minimum and maximum fraction digits)
 * <i> Optional fraction digits would go always after the forced ones
 */
const decimalPlacesRule = patternMask => {
  const patternWithoutEscapedText = patternRemoveEscapedText(patternMask);
  const patternPrecisionPart = splitStringInTwoParts(patternWithoutEscapedText, '.')[1];
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 0;
  if (!!patternPrecisionPart) {
    const trimmedPatternPrecisionPart = patternPrecisionPart.trim();
    if (stringIncludes(trimmedPatternPrecisionPart, '[')) {
      // If it contains optional fraction digits
      const patternPrecisionPartWithoutClosingBracket = trimmedPatternPrecisionPart.replace(']', '');
      // Isolates forced (left) vs optional (right) decimals
      const precisionSplitted = splitStringInTwoParts(patternPrecisionPartWithoutClosingBracket, '[');
      minimumFractionDigits = countChars(precisionSplitted[0], '0');
      maximumFractionDigits = minimumFractionDigits + countChars(precisionSplitted[1], '0');
    } else if (stringIncludes(trimmedPatternPrecisionPart, '#')) {
      // If it contains optional fraction digits marked with '#'
      minimumFractionDigits = countChars(trimmedPatternPrecisionPart.split('#')[0], '0');
      maximumFractionDigits = trimmedPatternPrecisionPart.length;
    } else if (stringIncludes(trimmedPatternPrecisionPart, 'X')) {
      // If it contains no-maximum fraction digits marked with 'X'
      minimumFractionDigits = countChars(trimmedPatternPrecisionPart.split('X')[0], '0');
      maximumFractionDigits = 500;
    } else {
      const fractionDigits = countChars(trimmedPatternPrecisionPart.split(' ')[0], '0');
      minimumFractionDigits = fractionDigits;
      maximumFractionDigits = fractionDigits;
    }
  }
  return {
    minimumFractionDigits,
    maximumFractionDigits
  };
};
export default decimalPlacesRule;