import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ReleaseNotesService } from '../release-notes.service';

@UntilDestroy()
@Component({
  selector: 'app-release-notes-panel',
  templateUrl: './release-notes-panel.component.html',
  styleUrls: ['./release-notes-panel.component.scss'],
})
export class ReleaseNotesPanelComponent implements OnInit {
  public isVisible = false;

  constructor(private releaseNotesService: ReleaseNotesService) {}

  ngOnInit(): void {
    this.releaseNotesService.visibility.pipe(untilDestroyed(this)).subscribe(isVisible => {
      this.isVisible = isVisible;
    });
  }

  close(): void {
    this.releaseNotesService.updateVisibility(false);
  }

  get expandClass(): string {
    return this.isVisible ? 'expanded' : 'collapsed';
  }
}
