<section class="app mat-typography flex flex-col">
  @if (userLoaded) {
    <app-header> </app-header>
  }

  <main class="flex-grow h-full w-full min-h-full min-w-full" role="main">
    <router-outlet></router-outlet>
  </main>

  @if (userLoaded) {
    <app-footer> </app-footer>
  }
</section>
@if (userLoaded) {
  <app-release-notes-panel></app-release-notes-panel>
}
