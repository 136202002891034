<div class="chart-container">
  <h2>{{ chartTitle }}</h2>
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    (chartInstance)="onChartCreation($event)"
    style="width: 100%; display: block"
  >
  </highcharts-chart>
</div>
