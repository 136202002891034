import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { PageHeaderComponent } from './page-header/page-header.component';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, HeaderMenuComponent, PageHeaderComponent],
  imports: [SharedModule],
  exports: [FooterComponent, HeaderComponent, PageHeaderComponent],
})
export class LayoutModule {}
