<div class="chart-container">
  <h2>Performance Over Time</h2>
  <div class="tabs">
    <app-card-tab-nav
      [tabs]="tabs"
      [removePadding]="true"
      (selectedIndexChange)="tabChanged($event)"
    ></app-card-tab-nav>
  </div>
  @if (isLoading) {
    <div class="overlay-spinner-container">
      <mat-spinner class="spinner"> </mat-spinner>
    </div>
  }
  @if (chartOptions) {
    <highcharts-chart
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      (chartInstance)="onChartCreation($event)"
      style="width: 100%; display: block; -webkit-backface-visibility: hidden"
      >
    </highcharts-chart>
  }
</div>
