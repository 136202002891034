import { Injectable } from '@angular/core';
import { first, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { ApiService } from '@comsig/core';

import { ReleaseNotes, ReleaseNotesEnvelope, ReleaseNotesOptions } from './release-notes.model';
import { UserActivitiesService } from '../shared/user-activities';

@Injectable()
export class ReleaseNotesService {
  protected endpoint = 'release-notes';

  public group = 'tracker_app';

  private visibilitySubject = new BehaviorSubject<boolean>(false);
  public visibility = this.visibilitySubject.asObservable();

  private unreadUpdatesCountSubject = new BehaviorSubject<number>(0);
  public unreadUpdatesCount = this.unreadUpdatesCountSubject.asObservable();

  private releaseNoteTimestamps: DateTime[];

  constructor(
    protected api: ApiService,
    private userActivitiesService: UserActivitiesService
  ) {}

  save(releaseNotes): Observable<void> {
    const { id, ...json } = releaseNotes;

    const payload: ReleaseNotesEnvelope = {
      id,
      group: this.group,
      json,
    };

    if (id === undefined) {
      return this.api.post(`${this.endpoint}`, payload);
    }

    return this.api.put(`${this.endpoint}/${id}`, payload);
  }

  delete(releaseNotes): Observable<void> {
    return this.api.delete(`${this.endpoint}/${releaseNotes.id}`);
  }

  getLatest(): Observable<ReleaseNotes[]> {
    const params: ReleaseNotesOptions = {
      group: this.group,
    };

    return this.api.get(`${this.endpoint}`, params).pipe(
      tap(
        (releaseNoteEnvelopes) =>
          (this.releaseNoteTimestamps = releaseNoteEnvelopes.map((releaseNoteEnvelope) =>
            DateTime.fromISO(releaseNoteEnvelope.createdAt)
          ))
      ),
      map((releaseNoteEnvelopes: ReleaseNotesEnvelope[]) => {
        return releaseNoteEnvelopes.map((releaseNoteEnvelope: ReleaseNotesEnvelope) => {
          return {
            ...releaseNoteEnvelope.json,
            id: releaseNoteEnvelope.id,
            group: releaseNoteEnvelope.group,
          };
        });
      })
    );
  }

  countUnread(): void {
    this.userActivitiesService
      .getList({
        size: 1,
        feature: 'RELEASE_NOTES',
      })
      .pipe(first())
      .subscribe((userActivitesPage) => {
        const userActivities = userActivitesPage.content;

        const mostRecentReleaseNotesViewAt =
          userActivities.length > 0 ? DateTime.fromISO(userActivities[0].timestamp) : null;

        const numUnread =
          mostRecentReleaseNotesViewAt === null
            ? 0
            : this.releaseNoteTimestamps.filter(
                (releaseNoteTimestamp) => releaseNoteTimestamp > mostRecentReleaseNotesViewAt
              ).length;

        this.updateUnreadUpdatesCount(numUnread);
      });
  }

  updateVisibility(isVisible: boolean): void {
    this.visibilitySubject.next(isVisible);

    if (isVisible) {
      if (this.unreadUpdatesCountSubject.value > 0) {
        this.updateUnreadUpdatesCount(0);
      }

      this.userActivitiesService.storeActivity({
        feature: 'RELEASE_NOTES',
        action: 'VIEW',
      });
    }
  }

  toggleVisibility(): void {
    this.updateVisibility(!this.visibilitySubject.value);
  }

  updateUnreadUpdatesCount(count: number) {
    this.unreadUpdatesCountSubject.next(count);
  }
}
